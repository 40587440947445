/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 01/10/22
 * Time: 2:42 PM
 * Project: W-CRM
 */
import {createSlice} from "@reduxjs/toolkit";
import apiService from "../../../api/apiService";
import {endPoints} from "../../../api/endpoints";
import {formatAxiosErrorMessage, qargsToQstring} from "../../../utils/common";
import {apiUrlBuilder} from "../../../utils/util";

const LeadWidgetInitialState = {
    retrieve: {
        isLoading: false,
        data: null,
        error: null,
    },
    makeAppointment: {
        isLoading: false,
        data: null,
        error: null,
    },
    fetchedAppointments: {
        isLoading: false,
        data: null,
        error: null,
    },
    slots: {
        isLoading: false,
        data: null,
        error: null,
    },
};

export const LeadWidgetSlice = createSlice({
    name: "lead_widget",
    initialState: {
        ...LeadWidgetInitialState,
    },
    reducers: {
        makeAppointmentRequesting: (state, action) => {
            state.makeAppointment.isLoading = true;
        },
        makeAppointmentSuccess: (state, action) => {
            state.makeAppointment.isLoading = false;
            state.makeAppointment.error = null;
            state.makeAppointment.data = action.payload.data;
        },
        makeAppointmentError: (state, action) => {
            state.makeAppointment.isLoading = false;
            state.makeAppointment.error = action.payload;
        },
        makeAppointmentReset: (state, action) => {
            state.makeAppointment = LeadWidgetInitialState.makeAppointment;
        },
        retrieveAppointmentRequesting: (state, action) => {
            state.retrieve.isLoading = true;
        },
        retrieveAppointmentSuccess: (state, action) => {
            state.retrieve.isLoading = false;
            state.retrieve.error = null;
            state.retrieve.data = action.payload.data;
        },
        retrieveAppointmentError: (state, action) => {
            state.retrieve.isLoading = false;
            state.retrieve.error = action.payload;
        },
        retrieveAppointmentReset: (state, action) => {
            state.retrieve = LeadWidgetInitialState.retrieve;
        },
        FetchSlotRequesting: (state, action) => {
            state.slots.isLoading = true;
            state.slots.data = null;
        },
        FetchSlotSuccess: (state, action) => {
            state.slots.isLoading = false;
            state.slots.error = null;
            state.slots.data = action.payload.data;
        },
        FetchSlotError: (state, action) => {
            state.slots.isLoading = false;
            state.slots.error = action.payload;
            state.slots.data = null;
        },
        FetchSlotReset: (state, action) => {
            state.slots = LeadWidgetInitialState.slots;
        },

        FetchedAppointmentRequesting: (state, action) => {
            state.fetchedAppointments.isLoading = true;
        },
        FetchedAppointmentSuccess: (state, action) => {
            state.fetchedAppointments.isLoading = false;
            state.fetchedAppointments.error = null;
            state.fetchedAppointments.data = action.payload.data;
        },
        FetchedAppointmentError: (state, action) => {
            state.fetchedAppointments.isLoading = false;
            state.fetchedAppointments.error = action.payload;
        },
        FetchedAppointmentReset: (state, action) => {
            state.fetchedAppointments = LeadWidgetInitialState.fetchedAppointments;
        },

        LeadWidgetMasterReset: () => LeadWidgetInitialState,
    },
});

export const getSlotsAPI = (data) => async (dispatch) => {
    dispatch(FetchSlotRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.lead.appointment, null, data), {})
        .then((response) => {
            dispatch(FetchSlotSuccess(response.data));
        })
        .catch((error) => dispatch(FetchSlotError(formatAxiosErrorMessage(error))));
};

export const makeAppointmentAPI = (data) => async (dispatch) => {
    dispatch(makeAppointmentRequesting());
    apiService
        .post(`${endPoints.lead.get}`, data)
        .then((response) => {
            dispatch(makeAppointmentSuccess(response.data));
        })
        .catch((error) => {
            dispatch(makeAppointmentError(formatAxiosErrorMessage(error)));
        });
};
export const appointmentBookingAPI = (data) => async (dispatch) => {
    dispatch(makeAppointmentRequesting());
    apiService
        .post(`${endPoints.lead.calendarAppointment}`, data)
        .then((response) => {
            dispatch(makeAppointmentSuccess(response.data));
        })
        .catch((error) =>
            dispatch(makeAppointmentError(formatAxiosErrorMessage(error)))
        );
};
export const updateAppointmentAPI = (data, id, token = null) => async (dispatch) => {
    dispatch(makeAppointmentRequesting());

    let url = `${endPoints.lead.calendarAppointment}${id}/`
    if (token) {
        url = url + `?t=${token}`
    }
    apiService
        .patch(url, data)
        .then((response) => {
            dispatch(makeAppointmentSuccess(response.data));
        })
        .catch((error) =>
            dispatch(makeAppointmentError(formatAxiosErrorMessage(error)))
        );
};

export const updateOtherAppointmentAPI = (data) => async (dispatch) => {
    dispatch(makeAppointmentRequesting());
    apiService
        .patch(endPoints.lead.other_event, data)
        .then((response) => {
            dispatch(makeAppointmentSuccess(response.data));
        })
        .catch((error) =>
            dispatch(makeAppointmentError(formatAxiosErrorMessage(error)))
        );
};

export const retrieveAppointmentAPI = (id) => async (dispatch) => {
    dispatch(retrieveAppointmentRequesting());
    apiService
        .get(`${endPoints.lead.calendarAppointment}${id}/`, {})
        .then((response) => {
            dispatch(retrieveAppointmentSuccess(response.data));
        })
        .catch((error) =>
            dispatch(retrieveAppointmentError(formatAxiosErrorMessage(error)))
        );
};

export const fetchAppointmentAPI = (id, query) => async (dispatch) => {
    let stringQuery = qargsToQstring(query);
    dispatch(FetchedAppointmentRequesting());
    apiService
        .get(endPoints.lead.leadAppointments.replace(":id", id), {})
        .then((response) => {
            dispatch(FetchedAppointmentSuccess(response.data));
        })
        .catch((error) =>
            dispatch(FetchedAppointmentError(formatAxiosErrorMessage(error)))
        );
};

export const {
    makeAppointmentRequesting,
    makeAppointmentSuccess,
    makeAppointmentError,
    makeAppointmentReset,

    FetchSlotSuccess,
    FetchSlotRequesting,
    FetchSlotError,
    FetchSlotReset,

    retrieveAppointmentRequesting,
    retrieveAppointmentSuccess,
    retrieveAppointmentError,
    retrieveAppointmentReset,

    FetchedAppointmentRequesting,
    FetchedAppointmentSuccess,
    FetchedAppointmentError,
    FetchedAppointmentReset,

    LeadWidgetMasterReset,
} = LeadWidgetSlice.actions;
export const LeadWidgetState = (state) => state.lead_widget;
export default LeadWidgetSlice.reducer;
