/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 29/08/22
 * Time: 10:02 AM
 * Project: W-CRM
 */
import React, {forwardRef, useEffect, useMemo, useState} from "react";
import "./style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../Input/Input";
import FilterIcon from "../../icon/FilterIcon";
import {DATE_DISPLAY_FORMAT, DATE_FORMAT, DATEPICKER_DATE_FORMAT, FilterType,} from "../../utils/constants";
import SearchTextField from "../SearchTextField/SearchTextField";
import userObserver from "../../utils/userObserver";
import ModernSelect from "../ModernSelect/ModernSelect";
import Close from "../../icon/Close";
import moment from "moment/moment";
import CloseCircle from "../../icon/CloseCircle";

export default function Filter({
                                   isDateFilter = true,
                                   isSearchFilter = true,
                                   isOptionFilter = true,
                                   options = [
                                       {id: "option A", value: "option A"},
                                       {id: "option B", value: "option B"},
                                   ],
                                   dateFilterDefault,
                                   optionFilterDefault,
                                   customFilter,
                                   extraActions = [],
                                   showExtraActions = true,
                                   dateFilterClear = false,
                                   onSearchChange = () => {
                                   },
                                   onDateChange = () => {
                                   },
                                   onOptionChange = () => {
                                   },
                                   placeholder,
                               }) {
    const [mobileModel, setMobileModel] = useState(false);
    const [selectedOption, setSelectedOption] = useState(optionFilterDefault);
    // Date Filter Start
    const [startDate, setStartDate] = useState(dateFilterDefault.start ? new Date(dateFilterDefault.start) : null);
    const [endDate, setEndDate] = useState(dateFilterDefault.end ? new Date(dateFilterDefault.end) : null);
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start)
        setEndDate(end)
        const formattedStart = moment(start).format(DATE_FORMAT);
        const formattedEnd = end !== null ? moment(end).format(DATE_FORMAT) : null;
        onDateChange({start: formattedStart, end: formattedEnd});
    };

    useEffect(() => {
        let displayString = ""
        if (startDate && endDate) {
            displayString = `${moment(startDate).format(DATE_DISPLAY_FORMAT)} - ${moment(endDate).format(DATE_DISPLAY_FORMAT)}`
        } else if (startDate) {
            displayString = `${moment(startDate).format(DATE_DISPLAY_FORMAT)}`
        }
        setInputFilterValue(displayString);

    }, [endDate, startDate]);

    const [inputFilterValue, setInputFilterValue] = useState("");

    const clearDateFilter = (e) => {
        e.stopPropagation();
        setInputFilterValue("");
        setStartDate(null);
        setEndDate(null);
        onDateChange({
            start: null,
            end: null,
        });
    };

    const FilterInput = forwardRef(({value, onClick}, ref) => (
        <Input
            size={"large"}
            wrapperClassName={"filter-date-picker-input-wrapper"}
            placeHolder={[placeholder[FilterType.dateRange]]}
            onClick={onClick}
            showError={false}
            preValue={inputFilterValue}
            trailing={
                dateFilterClear && inputFilterValue.length > 0 ? (
                    <CloseCircle strokeWidth={2} onClick={clearDateFilter}/>
                ) : (
                    <></>
                )
            }
        />
    ));
    // Date Filter End

    const [matches, ref] = userObserver();

    const MobileBurgerCondition = useMemo(() => {
        return !matches.desktop && (matches.tablet || matches.mobile);
    }, [matches.desktop, matches.mobile, matches.tablet]);

    const displayOnLoad = useMemo(() => {
        return !matches.desktop && !matches.tablet && !matches.mobile;
    }, [matches.desktop, matches.mobile, matches.tablet]);

    const [overrideFilterBurger, setOverrideFilterBurger] = useState(true);
    useEffect(() => {
        if (MobileBurgerCondition) {
            const activeFilters = [
                isDateFilter,
                isOptionFilter,
                isSearchFilter,
            ].filter((i) => i).length;
            if (activeFilters === 1) {
                setMobileModel(true);
                setOverrideFilterBurger(false);
            }
        }
    }, [MobileBurgerCondition]);

    return (
        <div className={"filter-parent-container"} ref={ref}>
            <div className={"filter-item-holder container row"}>
                {(isSearchFilter && matches.desktop) || displayOnLoad ? (
                    <div className={"col-3"}>
                        <SearchTextField
                            onChange={onSearchChange}
                            placeholder={placeholder[FilterType.search]}
                        />
                    </div>
                ) : (
                    <></>
                )}

                {(isOptionFilter && matches.desktop) || displayOnLoad ? (
                    <div className={"col-3"}>
                        <ModernSelect
                            size={"large"}
                            options={options}
                            preSelected={selectedOption}
                            extraActions={extraActions}
                            showExtraActions={showExtraActions}
                            onSelect={(value) => {
                                onOptionChange(value);
                                setSelectedOption(value);
                            }}
                            placeholder={placeholder[FilterType.option]}
                            isSearch={false}
                        />
                    </div>
                ) : (
                    <></>
                )}
                {(isDateFilter && matches.desktop) || displayOnLoad ? (
                    <div className={"col-3"}>
                        <DatePicker
                            dateFormat={DATEPICKER_DATE_FORMAT}
                            customInput={<FilterInput/>}
                            showPopperArrow={false}
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            todayButton="Select Today"
                            endDate={endDate}
                            selectsRange
                        />
                    </div>
                ) : (
                    <></>
                )}

                {(customFilter && matches.desktop) || displayOnLoad ? (
                    <div className={"col-3"}>
                        {customFilter}
                    </div>
                ) : (
                    <></>
                )}

                {MobileBurgerCondition && overrideFilterBurger && (
                    <div
                        className={"filter-toggle"}
                        onClick={() => {
                            if (mobileModel) {
                                setMobileModel(false);
                            } else {
                                setMobileModel(true);
                            }
                        }}
                    >
                        <div className={"filter-mobile-menu-icon"}>
                            {mobileModel ? (
                                <Close strokeWidth={1.6} className={"w-1-5"}/>
                            ) : (
                                <FilterIcon strokeWidth={1.6} className={"w-1-5"}/>
                            )}
                        </div>
                    </div>
                )}
                <div className={"filter-mob-holder"}>
                    {mobileModel &&
                        !matches.desktop &&
                        (matches.mobile || matches.tablet) && (
                            <>
                                {isSearchFilter && (
                                    <div className={"filter-item"}>
                                        <SearchTextField
                                            onChange={onSearchChange}
                                            placeholder={placeholder[FilterType.search]}
                                        />
                                    </div>
                                )}
                                {isOptionFilter && (
                                    <div className={"filter-item"}>
                                        <ModernSelect
                                            placeholder={placeholder[FilterType.option]}
                                            options={options}
                                            preSelected={selectedOption}
                                            extraActions={extraActions}
                                            showExtraActions={showExtraActions}
                                            onSelect={(value) => {
                                                onOptionChange(value);
                                                setSelectedOption(value);
                                            }}
                                            size={"large"}
                                        />
                                    </div>
                                )}

                                {isDateFilter && (
                                    <div className={"filter-item"}>
                                        <DatePicker
                                            dateFormat={DATEPICKER_DATE_FORMAT}
                                            customInput={<FilterInput/>}
                                            showPopperArrow={false}
                                            selected={startDate}
                                            onChange={onChange}
                                            startDate={startDate}
                                            todayButton="Select Today"
                                            endDate={endDate}
                                            selectsRange
                                        />
                                    </div>
                                )}

                                {customFilter &&
                                    <div className={"filter-item"}>{customFilter}</div>
                                }

                            </>
                        )}
                </div>
            </div>
        </div>
    );
}
