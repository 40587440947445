import {createSlice} from "@reduxjs/toolkit"
import apiService from "../../api/apiService";
import {formatAxiosErrorMessage} from "../../utils/common";
import {endPoints} from "../../api/endpoints";
import {apiUrlBuilder} from "../../utils/util";


const CalendarIntegrationInitialState = {
    configuration: {
        isLoading: false,
        data: null,
        error: null,
    },
    createConfiguration: {
        isLoading: false,
        data: null,
        error: null,
    },
    deleteConfiguration: {
        isLoading: false,
        data: null,
        error: null,
    },
}

export const CalendarSlice = createSlice({
    name: "calendarintegration",
    initialState: {
        ...CalendarIntegrationInitialState
    }, reducers: {
        CalendarConfigurationRequesting: (state, action) => {
            state.configuration.isLoading = true
        },
        CalendarConfigurationSuccess: (state, action) => {
            state.configuration.isLoading = false
            state.configuration.error = null
            state.configuration.data = action.payload.data
        },
        CalendarConfigurationError: (state, action) => {
            state.configuration.isLoading = false
            state.configuration.error = action.payload
        },
        CalendarConfigurationReset: (state, action) => {
            state.configuration = CalendarIntegrationInitialState.configuration
        },

        CalendarCreateConfigurationRequesting: (state, action) => {
            state.createConfiguration.isLoading = true
        },
        CalendarCreateConfigurationSuccess: (state, action) => {
            state.createConfiguration.isLoading = false
            state.createConfiguration.error = null
            state.createConfiguration.data = action.payload.data
        },
        CalendarCreateConfigurationError: (state, action) => {
            state.createConfiguration.isLoading = false
            state.createConfiguration.error = action.payload
        },
        CalendarCreateConfigurationReset: (state, action) => {
            state.createConfiguration = CalendarIntegrationInitialState.createConfiguration
        },

        CalendarDeleteConfigurationRequesting: (state, action) => {
            state.deleteConfiguration.isLoading = true
        },
        CalendarDeleteConfigurationSuccess: (state, action) => {
            state.deleteConfiguration.isLoading = false
            state.deleteConfiguration.error = null
            state.deleteConfiguration.data = "deleted"
        },
        CalendarDeleteConfigurationError: (state, action) => {
            state.deleteConfiguration.isLoading = false
            state.deleteConfiguration.data = null
            state.deleteConfiguration.error = action.payload
        },
        CalendarDeleteConfigurationReset: (state, action) => {
            state.deleteConfiguration = CalendarIntegrationInitialState.deleteConfiguration
        },
    }
})


export const CalendarConfigurationAPI = () => async dispatch => {
    dispatch(CalendarConfigurationRequesting());
    apiService
        .get(endPoints.integration.calendar.config, {})
        .then((response) => {
            dispatch(CalendarConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(CalendarConfigurationError(formatAxiosErrorMessage(error))));
}

export const CalendarCreateConfigurationAPI = (data) => async dispatch => {
    dispatch(CalendarCreateConfigurationRequesting());
    apiService
        .post(apiUrlBuilder(endPoints.integration.calendar.config, null, {}), data)
        .then((response) => {
            dispatch(CalendarCreateConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(CalendarCreateConfigurationError(formatAxiosErrorMessage(error))));
}
export const CalendarDeleteConfigurationAPI = (id, force = false) => async dispatch => {
    dispatch(CalendarDeleteConfigurationRequesting());
    apiService
        .delete(apiUrlBuilder(endPoints.integration.calendar.config + `${id}/`, null, {force: force}), {})
        .then((response) => {
            dispatch(CalendarDeleteConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(CalendarDeleteConfigurationError(formatAxiosErrorMessage(error))));
}

export const {

    CalendarConfigurationRequesting,
    CalendarConfigurationSuccess,
    CalendarConfigurationError,
    CalendarConfigurationReset,

    CalendarCreateConfigurationRequesting,
    CalendarCreateConfigurationSuccess,
    CalendarCreateConfigurationError,
    CalendarCreateConfigurationReset,

    CalendarDeleteConfigurationRequesting,
    CalendarDeleteConfigurationSuccess,
    CalendarDeleteConfigurationError,
    CalendarDeleteConfigurationReset,


    CalendarResetState

} = CalendarSlice.actions;
export const CalendarState = (state) => state.calendar
export default CalendarSlice.reducer